import React from "react";
import "./styles.scss";
import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Fact = (props) => {
  const { t } = useTranslation();
  const { handleOpen } = props
  return (
    
    <Row className="d-flex flex-column align-items-center fact">
      <Row>
        <h2>{t("Info.12")}</h2>
      </Row>
      <Row>
        <span className="subtext">{t("Info.13")}</span>
      </Row>
      <Row className="d-flex justify-content-center w-100">
        <div className="mybtnforinfo" onClick={() => handleOpen("Info.28", "utilitiumResearch")}>
          <span>
            {t(`Info.2`)}
          </span>
        </div>
      </Row>
    </Row>
  );
};

export default Fact;
