import React, { useState, useRef } from "react";
import Youtube from 'react-youtube';
import "./styles.scss";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const VideoBlock = () => {
  const { t } = useTranslation();
  const [playVideo, setPlayVideo] = useState({});
  const playBlock = useRef(null);
  const onReadyVideo = (e) => {
    playBlock.current.style.display = 'flex';
    setPlayVideo(e.target.playVideo());
    e.target.stopVideo();
  }
  const onPlayVideo = () => {
    playVideo.playVideo();
    playBlock.current.style.display = 'none';
  }
  const onPauseVideo = () => {
    playBlock.current.style.display = 'flex';
  }

  const opts = {
    height: '486',
    width: '',
    playerVars: {
      autoplay: "0",
    },
    
  };
  return (
    <Row className="d-flex flex-column align-items-center videoblock">
      <Row>
        <Col>
          <h1 className="header">{t(`Info.1`)}</h1>
        </Col>
      </Row>
      <Row className="Colls">
        <Youtube
          className="youtub"
          videoId="6JU99dal19g"
          opts={opts}
          onReady={(e) => onReadyVideo(e)}
          onPause={() => onPauseVideo()}
          onPlay={() => onPlayVideo()}
          

        />
        <div ref={playBlock} className="blockyoutube">
          <button className="ytp-large-play-button ytp-button" aria-label="Смотреть" onClick={() => onPlayVideo() }>
            <svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%">
              <path className="ytp-large-play-button-bg" d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z" fill="#f00"></path>
              <path d="M 45,24 27,14 27,34" fill="#fff"></path>
            </svg>
          </button>

        </div>

      </Row>
    </Row>

  );
};

export default VideoBlock;
