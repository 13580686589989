import React from "react";
import "./styles.scss";
import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const InfoForUs = (props) => {
  const { t } = useTranslation();
  const { handleOpen } = props;
  return (
    
    <Row className="d-flex flex-column align-items-center infoforus">
      <Row>
        <h2>{t("Info.22")}</h2>
      </Row>
      <Row>
        <span className="subtext">{t("Info.23")}</span>
      </Row>
      <Row className="d-flex justify-content-center w-100">
        <div className="mybtnforinfo" onClick={() => props.handleOpen("Info.29", "utilitiumConsultation")}>
          <span>
            {t(`Info.24`)}
          </span>
        </div>
      </Row>
    </Row>
  );
};


export default InfoForUs;
